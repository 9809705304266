<template>
  <div :class="className">
    <div class="container">
      <div
        class="row justify-content-between grid-item"
        :class="{ active: isActivated, disabled: isDisabled }"
        :style="getBackground"
      >
        <Spinner v-if="loading" />

        <SpecialPageCheckbox :item="item" v-if="isActivated && !loading" />
        <div class="col-md-12" v-if="!loading">
          <h6 class="title mt-3">{{ this.getTitle }}</h6>
        </div>
        <button
          class="btn btn-primary btn-sm btn-block blue-btn align-self-end mb-3 trigger__edit-button"
          @click="triggerEditButton"
          v-if="isActivated && !loading && !isDisabled && showEditButton"
        >
          Edit Page
        </button>
        <button
          class="btn btn-success btn-sm btn-block align-self-end mb-3"
          :class="{ disabled: button_disabled }"
          @click="triggerActivate"
          v-if="!isActivated && !loading"
        >
          Activate Page
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import DedicationModal from "./DedicationModal";
import CoverPageModal from "./CoverPageModal";
import TableOfContentsModal from "./TableOfContentsModal";
import SpecialPageCheckbox from "./SpecialPageCheckbox";
import Spinner from "./Spinner";
import TheFactsModal from "./TheFactsModal";
import HealthHistoryModal from "./HealthHistoryModal";
// import IntroductionModal from "./IntroductionModal";
import PageEditorModal from "./PageEditorModal";

export default {
  components: {
    SpecialPageCheckbox,
    Spinner,
  },
  data() {
    return {
      loading: true,
      activated: false,
      button_disabled: false,
      item: {},
    };
  },
  props: {
    cols: {
      type: Number,
      default: 6,
    },
    type: {
      type: String,
      default: "cover",
    },
  },
  computed: {
    ...mapGetters([
      "getDefaults",
      "getPages",
      "getGlobalSettings",
      "getPageDesigns",
    ]),
    className: function () {
      const cols = this.cols in [1, 2, 3, 4, 6] ? this.cols : 6;
      const cols_width = 12 / cols;
      return `col-lg-${cols_width} col-md-3 col-sm-4 mb-3`;
    },
    isActivated() {
      switch (this.type) {
        case "dedication":
          return parseInt(this.getDefaults?.dedication_page_id);
        case "toc":
          return parseInt(this.getDefaults?.toc_page_id);
        case "facts":
          return parseInt(this.getDefaults?.facts_page_id);
        case "health":
          return parseInt(this.getDefaults?.health_page_id);
        case "intro":
          return parseInt(this.getDefaults?.intro_page_id);
        case "final":
          return parseInt(this.getDefaults?.final_page_id);
        default:
        case "cover":
          return parseInt(this.getDefaults?.cover_page_id);
      }
    },
    isDisabled() {
      return !this.item?.legacy_journal;
    },
    getItem: {
      get() {
        return this.item;
      },
      set(value) {
        this.item = value;
      },
    },
    showEditButton() {
      if (this.type === `cover`) {
        return this.$root.designs === `1`;
      } else {
        return true;
      }
    },
    showDesigns() {
      return this.getGlobalSettings.designs == "1";
    },
    isDefault() {
      if (parseInt(this.getItem?.design_default) === 1) return true;
      if (this.getItem?.design_default === "1") return true;
      if (this.getItem?.design_default === "") return true;
      if (this.getItem?.design_default === "0") return false;
      if (this.getItem?.design_default) return true;
      return false;
    },
    getBackground() {
      if (!this.showDesigns) {
        return "";
      }
      if (this.isDefault && this.type !== `cover`) {
        const default_page = this.getPageDesigns.find(
          (d) => d.id === this.getDefaults.default_page
        );
        if (default_page?.image_src) {
          return `background-image:url(/wp-content/themes/${this.getTemplateFolder}${default_page.image_src})`;
        }
      }
      if (this.getItem?.design?.image_src) {
        return `background-image:url(/wp-content/themes/${this.getTemplateFolder}${this.getItem.design.image_src})`;
      }
      return "";
    },
    getTitle() {
      switch (this.type) {
        case "dedication":
          return "Dedication Page";
        case "toc":
          return "Table of Contents";
        case "facts":
          return "The Facts";
        case "health":
          return "Health History";
        case "intro":
          return "Introduction Page";
        case "final":
          return "Final Page";
        default:
        case "cover":
          return "Cover Page";
      }
    },
    getDefaultValueByType() {
      switch (this.type) {
        case "dedication":
          return this.getDefaults.dedication_page_id;
        case "toc":
          return this.getDefaults.toc_page_id;
        case "facts":
          return this.getDefaults.facts_page_id;
        case "health":
          return this.getDefaults.health_page_id;
        case "intro":
          return this.getDefaults.intro_page_id;
        case "final":
          return this.getDefaults.final_page_id;
        default:
        case "cover":
          return this.getDefaults.cover_page_id;
      }
    },
    getEditModal() {
      switch (this.type) {
        case "dedication":
          return PageEditorModal;
        case "toc":
          return TableOfContentsModal;
        case "facts":
          return TheFactsModal;
        case "health":
          return HealthHistoryModal;
        case "intro":
          return PageEditorModal;
        case "final":
          return PageEditorModal;
        default:
        case "cover":
          return CoverPageModal;
      }
    },
    keyByType() {
      switch (this.type) {
        case "dedication":
          return "dedication_page_id";
        case "toc":
          return "toc_page_id";
        case "facts":
          return "facts_page_id";
        case "health":
          return "health_page_id";
        case "intro":
          return "intro_page_id";
        case "final":
          return "final_page_id";
        case "cover":
          return "cover_page_id";
        default:
          return ``;
      }
    },
    getTemplateFolder() {
      return this.$root.template_name;
    },
  },
  methods: {
    triggerActivate() {
      if (this.button_disabled) return;
      this.button_disabled = true;
      this.$store.dispatch("activateSpecialPage", [
        this.type,
        ({ message, page_id, data, key }) => {
          data.design_default = 1;
          this.getItem = data;
          this.$store.commit("UPDATE_DEFAULTS", [key, page_id]);
          this.$toast.open({
            message,
            position: "bottom-left",
          });
        },
      ]);
    },
    triggerEditButton() {
      this.$modal.show(
        this.getEditModal,
        {
          item: this.getItem,
        },
        {
          name: "modal-page",
          resizable: true,
          minWidth: 880,
          minHeight: 605,
        },
        {
          resize: this.resizeModal,
        }
      );
    },
    resizeModal() {
      // console.log(this.$modal);
    },
    getDataValueByType(data) {
      switch (this.type) {
        case "cover":
          return data?.cover_page_id;
        case "dedication":
          return data?.dedication_page_id;
        case "toc":
          return data?.toc_page_id;
        case "facts":
          return data?.facts_page_id;
        case "health":
          return data?.health_page_id;
        case "intro":
          return data?.intro_page_id;
        case "final":
          return data?.final_page_id;
        default:
          return 0;
      }
    },
  },
  mounted() {
    this.$store.dispatch("loadDefaults", [
      [this.keyByType],
      (defaultValue) => {
        this.$store.dispatch("getPageById", [
          this.getDataValueByType(defaultValue),
          (data) => {
            this.item = data;
            this.loading = false;
          },
        ]);
      },
    ]);
  },
};
</script>
<style scoped>
.grid-item {
  font-size: 14px;
  text-align: center;
  border: 1px solid #0f8300;
  height: 252px;
  padding: 20px;
  box-shadow: 0 0 5px #000;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  background-size: cover;
}
.grid-item > div {
  opacity: 0.5;
}
.grid-item.active > div {
  opacity: 1;
}
.grid-item.disabled > div {
  opacity: 0.5;
}
input#activator {
  position: relative;
  right: 0;
}
</style>