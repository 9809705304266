<template>
  <div class="container">
    <spinner v-if="showLoading" />
    <div class="row" v-if="!showLoading">
      <SpecialPageGridItem :cols="cols" v-if="showSpecialPages" type="cover" />
      <SpecialPageGridItem
        :cols="cols"
        v-if="showSpecialPages"
        type="dedication"
      />
      <SpecialPageGridItem :cols="cols" v-if="showSpecialPages" type="toc" />
      <SpecialPageGridItem :cols="cols" v-if="showSpecialPages" type="intro" />
      <SortableGridItem
        class="draggable-grid"
        :cols="cols"
        v-for="(item, index) in sortedJournals"
        :index="index"
        :key="item.ID"
        :item="item"
      />
      <SpecialPageGridItem :cols="cols" v-if="showSpecialPages" type="final" />
    </div>
  </div>
</template>
<script>
import SortableGridItem from "./SortableGridItem";
import { mapGetters } from "vuex";
import Spinner from "./Spinner";
import SpecialPageGridItem from "./SpecialPageGridItem";

export default {
  components: {
    SortableGridItem,
    Spinner,
    SpecialPageGridItem,
  },
  data() {
    return {
      drag: false,
      loading: true,
    };
  },
  props: {
    cols: {
      type: Number,
      default: 6,
    },
    special: {
      type: String,
      default: "0",
    },
  },
  computed: {
    ...mapGetters(["getLegacyJournal", "getWritingGroupPages"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
      };
    },
    groupIds() {
      const ids = [];
      this.getWritingGroupPages.map((p) => ids.push(p.ID));
      return ids;
    },
    getActiveJournals: {
      get() {
        return this.getLegacyJournal
          .filter((j) => j.legacy_journal)
          .map((j) => {
            const index = this.groupIds.indexOf(parseInt(j.writing_group));
            j.writing_group_index = index >= 0 ? index + 1 : 9999;
            j.sort_me = `${j.writing_group_index}-${j.menu_order}`;
            return j;
          });
      },
    },
    sortedJournals() {
      const journals = this.getActiveJournals
        .filter(() => true)
        .sort((a, b) => a.menu_order - b.menu_order)
        .sort((a, b) => a.writing_group_index - b.writing_group_index)
        .sort((a, b) => a.sort_me - b.sort_me);
      return journals;
    },

    showSpecialPages() {
      return this.special == "1";
    },
    showLoading() {
      return this.getActiveJournals.length == 0;
    },
  },
  mounted() {
    this.$store.dispatch("loadDefaults", [
      [
        "default_font_family",
        "default_font_size",
        "default_page",
        "image_upload_limit",
        "image_upload_count",
      ],
      () => {
        this.$store.dispatch("loadLegacyJournal", [
          "1",
          () => {
            this.loading = false;
            this.$store.dispatch("loadWritingsGroupPages", () => {});
          },
        ]);
      },
    ]);
  },
  methods: {
    updatePosition() {
      this.$store.dispatch(
        "updateLegacyJournalPosition",
        ({ success, data: { message } }) => {
          if (success) {
            this.$toast.open({
              message,
              position: "bottom-left",
            });
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.draggable-grid {
  background: transparent !important;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>