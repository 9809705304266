<template>
  <div class="container">
    <button
      @click="showPicker"
      :style="{ backgroundColor: colorValue.hex }"
    ></button>
    <compact-picker
      v-model="colorValue"
      class="picker"
      :class="{ 'show-picker': show_picker }"
      @input="updateValue"
    />
  </div>
</template>

<script>
import { Compact } from "vue-color";

export default {
  components: {
    "compact-picker": Compact,
  },
  data() {
    return {
      colors: {
        hex: "#000000",
      },
      show_picker: false,
    };
  },
  props: {
    initialColor: {
      type: String,
      default: "#000000",
    },
  },
  computed: {
    colorValue: {
      get() {
        return this.colors || { hex: this.initialColor };
      },
      set(value) {
        this.colors = value;
      },
    },
  },
  methods: {
    updateValue(value) {
      this.show_picker = !this.show_picker;
      this.$emit("change", value);
    },
    showPicker() {
      this.show_picker = !this.show_picker;
    },
  },
  watch: {
    initialColor: function (newVal) {
      this.colors = { hex: newVal };
    },
  },
  mounted() {
    this.colors.hex = this.initialColor;
  },
};
</script>

<style scoped>
.picker {
  display: none;
  position: absolute;
  right: 0;
  z-index: 99;
}
.container {
  position: relative;
}
.show-picker {
  display: block;
}
button {
  width: 38px;
  height: 38px;
  display: block;
  border: none;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #ced4da;
  cursor: pointer;
  border-radius: 0.25rem;
}
</style>