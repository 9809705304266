<template>
  <div>
    <template v-if="filteredItems.length > 0">
      <h3>{{ group.post_title }}</h3>
      <draggable
        class="list-group"
        v-model="filteredItems"
        group="filteredItems"
        @start="drag = true"
        @end="drag = false"
        handle=".handle"
        @change="updatePosition"
      >
        <div
          :key="item.ID"
          v-for="item in filteredItems"
          class="list-group-item handle"
        >
          <TableOfContentsParentItem :item="item" />
        </div>
      </draggable>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PageEditorModal from "./PageEditorModal.vue";
import TableOfContentsParentItem from "./TableOfContentsParentItem.vue";
import draggable from "vuedraggable";

export default {
  components: { TableOfContentsParentItem, draggable },
  data() {
    return {
      isLoading: true,
    };
  },
  props: {
    group: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["getPages", "getLegacyJournal"]),
    filteredItems: {
      get() {
        return this.getLegacyJournal
          .filter((j) => j.legacy_journal)
          .filter((j) => parseInt(j.writing_group) === this.group.ID)
          .sort((a, b) => a.menu_order - b.menu_order);
      },
      set(value) {
        value.map((item, index) => {
          item.menu_order = index;
        });
      },
    },
    isActivated: {
      get() {
        return this.item.table_of_contents == "1";
      },
      set(value) {
        this.item.table_of_contents = value;
      },
    },
    myPages() {
      return this.getPages
        .filter((p) => p.writing_id == this.item.ID)
        .filter((p) => p.post_status == "publish")
        .sort((a, b) => a.page_position - b.page_position);
    },
  },
  methods: {
    updatePosition() {
      this.$store.dispatch("updateLegacyJournalPositionByGroup", [
        this.filteredItems,
        ({ success, data: { message } }) => {
          if (success) {
            this.$toast.open({
              message,
              position: "bottom-left",
            });
          }
        },
      ]);
    },
    activateItem() {
      this.isActivated = !this.isActivated;
      this.item.table_of_contents = this.isActivated ? "1" : "0";
      this.$store.dispatch("updatePageMeta", [
        this.item.ID,
        "table_of_contents",
        this.isActivated ? 1 : 0,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
          });
          // this.$emit("reload-preview");
        },
      ]);
    },

    triggerEdit() {
      this.$modal.show(
        PageEditorModal,
        {
          item: this.item,
          closeTo: "toc",
        },
        {
          name: "modal-" + this.item.ID,
        }
      );
    },
  },
  mounted() {
    // console.log(this.group);
  },
};
</script>
<style scoped>
.fa {
  cursor: pointer;
}
.fa.activated {
  color: green;
}
</style>