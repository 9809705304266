<template>
  <div class="container">
    <CloseModalButton @close="closeModal" />
    <PageNavButtons
      v-if="hasPageNav"
      :item="getItem"
      :item_id="getItemId"
      ref="viewNav"
      @nav-change="triggerChangeNav"
      :showPictures="false"
    />
    <div class="page-editor-modal row">
      <div class="col-md-7 pt-3 pb-3" v-if="active_view == `content`">
        <div class="card">
          <button
            class="btn btn-success btn-sm btn-save"
            v-if="show_save"
            @click="saveChanges"
          >
            <i class="fa fa-save"></i> Save
          </button>
          <Editor
            class="card-header"
            output-format="text"
            :inline="true"
            :initial-value="item.post_title"
            :init="{
              height: 300,
              max_height: 10,
              menubar: false,
              plugins: [],
              toolbar: '',
              statusbar: false,
            }"
            v-model="title"
            @onKeyUp="triggerUpdate"
            @onChange="triggerUpdate"
          />
        </div>
      </div>
      <div class="col-md-7 pt-3 pb-3" v-if="active_view == `design`">
        <page-designs-component
          groups="default"
          @set-design="setPageDesign"
          ref="page_design"
          :default="item.page_design"
        />
      </div>
      <div class="col-md-5 d-md-block d-sm-none pt-3 pb-3">
        <PdfPreviewModal :item="item" ref="preview" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CloseModalButton from "./CloseModalButton";
import PdfPreviewModal from "./PdfPreviewModal";
import PageNavButtons from "./PageNavButtons";
import Editor from "@tinymce/tinymce-vue";
import PageDesignsComponent from "./PageDesignsComponent.vue";

export default {
  components: {
    CloseModalButton,
    PdfPreviewModal,
    Editor,
    PageNavButtons,
    PageDesignsComponent,
  },
  data() {
    return {
      active_view: "content",
      selected_design: "",
      timer: 0,
      show_save: false,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["getDefaults", "getPageDesigns", "getPages"]),
    getSpecificDesigns() {
      return this.getPageDesigns.filter((d) =>
        d.groups.find((g) => g === "cover")
      );
    },
    getItem() {
      return this.item;
    },
    getItemId() {
      return parseInt(this.getDefaults.cover_page_id);
    },
    title: {
      get() {
        return this.item.post_title;
      },
      set(value) {
        this.item.post_title = value;
      },
    },
    hasPageNav() {
      return this.$root?.sidebar_tabs === `1`;
    },
  },
  methods: {
    setPageDesign() {
      const design = this.$refs.page_design.design;
      if (this.selected_design === design.id) return;
      this.selected_design = design.id;
      this.item.page_design = design.id;
      this.item.design = design;
      this.$store.dispatch("updatePageMeta", [
        this.item.ID,
        "page_design",
        `${design.id}`,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
          });
          this.reloadPreview();
        },
      ]);
    },
    reloadPreview() {
      this.$refs.preview.reload();
    },
    closeModal() {
      this.$modal.hide("modal-page");
    },
    triggerUpdate() {
      clearTimeout(this.timer);
      this.show_save = true;
      this.timer = setTimeout(() => {
        this.saveChanges();
      }, 5000);
    },
    saveChanges() {
      clearTimeout(this.timer);
      this.$store.dispatch("updatePage", [
        this.item.ID,
        this.item.post_title,
        this.item.post_content,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
            type: "success",
          });
          this.reloadPreview();
        },
      ]);
      this.show_save = false;
    },
    triggerChangeNav() {
      this.active_view = this.$refs.viewNav.isActive;
    },
  },
};
</script>
<style scoped>
.scrolled {
  height: 565px;
  overflow-y: scroll;
}
.card.page-design {
  min-height: 220px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
}
.card.page-design:hover {
  border: 1px solid green;
}
.card.page-design.active {
  border: 1px solid green;
}
.btn-save {
  position: absolute;
  z-index: 1;
  right: -10px;
  top: -10px;
}
</style>