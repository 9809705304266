<template>
  <div :class="className">
    <div class="container">
      <div class="row justify-content-between grid-item" :style="getBackground">
        <div class="col-md-12">
          <h6 class="title mt-3">Writing</h6>
          {{ item.stripped_title.substring(0, 80) }}{{ dotdotdot }}
        </div>
        <button
          @click="triggerEditPage"
          class="btn btn-primary btn-sm btn-block blue-btn align-self-end mb-3"
        >
          Edit Page
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PageEditorModal from "./PageEditorModal";
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      new_design: null,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    cols: {
      type: Number,
      default: 6,
    },
  },
  computed: {
    ...mapGetters(["getDefaults", "getPageDesigns", "getGlobalSettings"]),
    className: function () {
      const cols = this.cols in [1, 2, 3, 4, 6] ? this.cols : 6;
      const cols_width = 12 / cols;
      return `col-lg-${cols_width} col-md-3 col-sm-4 mb-3`;
    },
    isDefault() {
      if (parseInt(this.item.design_default) === 1) return true;
      if (this.item.design_default === "1") return true;
      if (this.item.design_default === "") return true;
      if (this.item.design_default === "0") return false;
      if (this.item.design_default) return true;
      return false;
    },
    showDesigns() {
      return this.getGlobalSettings.designs == "1";
    },
    getBackground() {
      if (!this.showDesigns) {
        return "";
      }
      if (this.new_design) {
        return `background-image:url(/wp-content/themes/${this.getTemplateFolder}${this.new_design.image_src})`;
      }
      if (this.isDefault) {
        const default_page = this.getPageDesigns.find(
          (d) => d.id === this.getDefaults.default_page
        );
        if (default_page?.image_src) {
          return `background-image:url(/wp-content/themes/${this.getTemplateFolder}${default_page.image_src})`;
        }
      }
      if (this.item?.design?.image_src) {
        return `background-image:url(/wp-content/themes/${this.getTemplateFolder}${this.item.design.image_src})`;
      }
      return "";
    },
    dotdotdot() {
      if (this.item.stripped_title.length > 80) {
        return "...";
      } else {
        return "";
      }
    },
    getTemplateFolder() {
      return this.$root.template_name;
    },
  },
  methods: {
    triggerEditPage() {
      this.showItem = false;
      this.$modal.show(
        PageEditorModal,
        {
          item: this.item,
          loading: true,
        },
        {
          name: "modal-" + this.item.ID,
          ref: "pageModal",
          resizable: true,
          minWidth: 880,
          minHeight: 605,
        }
      );
    },
  },
};
</script>
<style scoped>
.grid-item {
  font-size: 14px;
  text-align: center;
  border: 1px solid #0f8300;
  height: 252px;
  padding: 20px;
  box-shadow: 0 0 5px #000;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
</style>