<template>
  <div class="height-100">
    <button
      class="btn btn-success btn-sm btn-save"
      v-if="show_save"
      @click="triggerSave"
    >
      <i class="fa fa-save"></i> Save
    </button>

    <div class="card height-100">
      <spinner v-if="loading" />
      <div v-else class="card-body">
        <div class="row" v-if="showTitle">
          <div class="col-md-9">
            <label for="title" class="form-label"><strong>Title</strong></label>
            <input
              @change="showSaveButton"
              @keydown="showSaveButton"
              type="text"
              id="title"
              class="form-control mb-2"
              v-model="cover_page_title"
              :style="{ fontFamily: currentFont }"
            />
          </div>
          <div class="col-md-2">
            <label for="font-size" class="form-label"
              ><strong>Font Size</strong></label
            >
            <select
              id="font-size"
              class="custom-select custom-select-md mb-3"
              v-model="titleSize"
              :style="{ fontFamily: currentFont }"
              @change="showSaveButton"
            >
              <option v-for="size in fontSizes" :key="size" :value="size">
                {{ size }}px
              </option>
            </select>
          </div>
          <div class="col-md-1">
            <label class="form-label"><strong>Color</strong></label>
            <ColorPickerInput
              @change="changeTitleColor"
              :initial-color="titleColor"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <label for="title" class="form-label"
              ><strong>Subtitle</strong></label
            >
            <input
              @change="showSaveButton"
              @keydown="showSaveButton"
              type="text"
              id="title"
              class="form-control mb-2"
              v-model="cover_page_subtitle"
              :style="{ fontFamily: currentFont }"
            />
          </div>
          <div class="col-md-2">
            <label for="font-size" class="form-label"
              ><strong>Font Size</strong></label
            >
            <select
              id="font-size"
              class="custom-select custom-select-md mb-3"
              v-model="subtitleSize"
              :style="{ fontFamily: currentFont }"
              @change="showSaveButton"
            >
              <option v-for="size in fontSizes" :key="size" :value="size">
                {{ size }}px
              </option>
            </select>
          </div>
          <div class="col-md-1">
            <label class="form-label"><strong>Color</strong></label>
            <ColorPickerInput
              @change="changeSubtitleColor"
              :initial-color="subtitleColor"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <label for="author-name" class="form-label"
              ><strong>Author's Name</strong></label
            >
            <input
              @change="showSaveButton"
              @keydown="showSaveButton"
              type="text"
              id="author-name"
              class="form-control mb-2"
              v-model="cover_page_author"
              :style="{ fontFamily: currentFont }"
            />
          </div>
          <div class="col-md-2">
            <label for="font-size" class="form-label"
              ><strong>Font Size</strong></label
            >
            <select
              id="font-size"
              class="custom-select custom-select-md mb-3"
              v-model="authorSize"
              :style="{ fontFamily: currentFont }"
              @change="showSaveButton"
            >
              <option v-for="size in fontSizes" :key="size" :value="size">
                {{ size }}px
              </option>
            </select>
          </div>
          <div class="col-md-1">
            <label class="form-label"><strong>Color</strong></label>
            <ColorPickerInput
              @change="changeAuthorColor"
              :initial-color="authorColor"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label for="font-style" class="form-label"
              ><strong>Font Style</strong></label
            >

            <select
              id="font-style"
              class="custom-select custom-select-md mb-3"
              v-model="currentFont"
              :style="{ fontFamily: currentFont }"
              @change="showSaveButton"
            >
              <option
                v-for="font in fonts"
                :key="font"
                :style="{ fontFamily: font }"
              >
                {{ font }}
              </option>
            </select>
            <div class="reset">
              <span @click="triggerReset">Reset Settings</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fonts, fontSizes } from "../data/editor-config.js";
import { mapGetters } from "vuex";
import Spinner from "./Spinner";
import ColorPickerInput from "./ColorPickerInput.vue";

export default {
  components: { Spinner, ColorPickerInput },
  data() {
    return {
      fontFamily: "Lato",
      fontSize: 14,
      show_save: false,
      cover_page_title: "",
      cover_page_title_size: 60,
      cover_page_title_color: "#000000",
      cover_page_subtitle: "",
      cover_page_subtitle_size: 38,
      cover_page_subtitle_color: "#000000",
      cover_page_author: "",
      cover_page_author_size: 42,
      cover_page_author_color: "#000000",
      loading: true,
      timer: null,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
          id: 0,
        };
      },
    },
  },
  computed: {
    ...mapGetters(["getDefaults"]),
    fontSizes() {
      return fontSizes.filter((s) => s !== "Default (14px)");
    },
    fonts() {
      return fonts;
    },
    currentFont: {
      get() {
        return this.fontFamily;
      },
      set(value) {
        this.fontFamily = value;
      },
    },
    currentSize: {
      get() {
        return this.fontSize;
      },
      set(value) {
        this.fontSize = parseInt(value);
      },
    },
    titleSize: {
      get() {
        return this.cover_page_title_size;
      },
      set(value) {
        this.cover_page_title_size = parseInt(value);
      },
    },
    titleColor: {
      get() {
        return this.cover_page_title_color;
      },
      set(value) {
        this.cover_page_title_color = value;
      },
    },
    subtitleSize: {
      get() {
        return this.cover_page_subtitle_size;
      },
      set(value) {
        this.cover_page_subtitle_size = parseInt(value);
      },
    },
    subtitleColor: {
      get() {
        return this.cover_page_subtitle_color;
      },
      set(value) {
        this.cover_page_subtitle_color = value;
      },
    },
    authorSize: {
      get() {
        return this.cover_page_author_size;
      },
      set(value) {
        this.cover_page_author_size = parseInt(value);
      },
    },
    authorColor: {
      get() {
        return this.cover_page_author_color;
      },
      set(value) {
        this.cover_page_author_color = value;
      },
    },
    showTitle() {
      return this.item?.design?.show_title;
    },
  },
  methods: {
    showSaveButton() {
      this.show_save = true;
      this.triggerTimer();
    },
    changeTitleColor(value) {
      this.titleColor = value.hex;
      this.showSaveButton();
    },
    changeSubtitleColor(value) {
      this.subtitleColor = value.hex;
      this.showSaveButton();
    },
    changeAuthorColor(value) {
      this.authorColor = value.hex;
      this.showSaveButton();
    },
    triggerTimer() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.triggerSave();
      }, 5000);
    },
    triggerSave() {
      clearTimeout(this.timer);
      this.show_save = false;
      this.$store.dispatch("saveCoverPageSettings", [
        {
          currentFont: this.currentFont,
          currentSize: this.currentSize,
          title: this.cover_page_title,
          title_size: this.cover_page_title_size,
          title_color: this.cover_page_title_color,
          subtitle: this.cover_page_subtitle,
          subtitle_size: this.cover_page_subtitle_size,
          subtitle_color: this.cover_page_subtitle_color,
          author: this.cover_page_author,
          author_size: this.cover_page_author_size,
          author_color: this.cover_page_author_color,
        },
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
            type: "success",
          });
          this.$emit("update");
        },
      ]);
    },
    triggerReset() {
      if (confirm("Are you sure?")) {
        this.fontFamily = "Lato";
        this.fontSize = 14;
        this.cover_page_title = "";
        this.cover_page_title_size = 60;
        this.cover_page_title_color = "#000000";
        this.cover_page_subtitle = "";
        this.cover_page_subtitle_size = 38;
        this.cover_page_subtitle_color = "#000000";
        this.cover_page_author = "";
        this.cover_page_author_size = 42;
        this.cover_page_author_color = "#000000";
        this.triggerSave();
      }
    },
  },
  mounted() {
    this.$store.dispatch("loadDefaults", [
      [
        "default_font_family",
        "default_font_size",
        "cover_page_title",
        "cover_page_title_size",
        "cover_page_title_color",
        "cover_page_subtitle",
        "cover_page_subtitle_size",
        "cover_page_subtitle_color",
        "cover_page_author",
        "cover_page_author_size",
        "cover_page_author_color",
      ],
      (data) => {
        if (data.default_font_family !== "") {
          this.currentFont = data.default_font_family;
        }
        if (data.default_font_size !== "") {
          this.currentSize = parseInt(data.default_font_size);
        }
        if (data.cover_page_title !== "") {
          this.cover_page_title = data.cover_page_title;
        }
        if (data.cover_page_title_size !== "") {
          this.cover_page_title_size = data.cover_page_title_size;
        }
        if (data.cover_page_title_color !== "") {
          this.cover_page_title_color = data.cover_page_title_color;
        }
        if (data.cover_page_subtitle !== "") {
          this.cover_page_subtitle = data.cover_page_subtitle;
        }
        if (data.cover_page_subtitle_size !== "") {
          this.cover_page_subtitle_size = data.cover_page_subtitle_size;
        }
        if (data.cover_page_subtitle_color !== "") {
          this.cover_page_subtitle_color = data.cover_page_subtitle_color;
        }
        if (data.cover_page_author !== "") {
          this.cover_page_author = data.cover_page_author;
        }
        if (data.cover_page_author_size !== "") {
          this.cover_page_author_size = data.cover_page_author_size;
        }
        if (data.cover_page_author_color !== "") {
          this.cover_page_author_color = data.cover_page_author_color;
        }
        this.loading = false;
      },
    ]);
  },
};
</script>
<style scoped>
.btn-save {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 5px;
}
.reset {
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
  width: 100%;
  font-size: 12px;
}
</style>