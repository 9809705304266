<template>
  <div class="container scrolled">
    <div class="row">
      <div
        v-for="design in filteredDesigns"
        :key="design.id"
        class="col-lg-4 col-md-4 col-sm-4 col-xs-6 mb-3"
      >
        <div
          class="card page-design"
          :class="{
            active: defaultSelection == design.id,
          }"
          :style="backgroundImage(design)"
          @click="setPageDesign(design)"
        >
          <div class="card-body d-flex flex-column">
            <span v-if="design.image_src === ``" class="no-design">
              No Design
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      design: {},
      selected: "",
    };
  },
  props: {
    groups: {
      type: String,
      default: "",
    },
    default: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["getPageDesigns"]),
    filteredDesigns() {
      return this.getPageDesigns.filter((d) => d.groups.includes(this.groups));
    },
    defaultSelection: {
      get() {
        return this.selected;
      },
      set(v) {
        this.selected = v;
      },
    },
    getTemplateFolder() {
      return this.$root.template_name;
    },
  },
  methods: {
    setPageDesign(design) {
      this.design = design;
      this.defaultSelection = design.id;
      this.$emit("set-design");
    },
    backgroundImage(design) {
      if (design.image_src) {
        return `background-image:url(/wp-content/themes/${this.getTemplateFolder}${design.image_src})`;
      } else {
        return ``;
      }
    },
  },
  mounted() {
    this.selected = this.default;
  },
};
</script>
<style scoped>
.card {
  min-height: 220px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
}
.card:hover {
  border: 5px solid green;
}
.card.active {
  border: 5px solid green;
  opacity: 0.5;
}
.no-design {
  text-transform: uppercase;
  display: block;
  margin: auto auto;
  font-weight: 600;
}
</style>