<template>
  <div>
    <i
      class="fa fa-check-square mr-3"
      :class="{ activated: isActivated }"
      @click="activateItem"
    ></i>

    <button
      type="button"
      class="btn btn-sm btn-success float-right"
      @click="triggerEdit"
      v-if="isActivated"
    >
      Edit
    </button>

    {{ item.stripped_title }}
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PageEditorModal from "./PageEditorModal.vue";

export default {
  components: {},
  data() {
    return {
      isLoading: true,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["getPages"]),
    isActivated: {
      get() {
        return this.item.table_of_contents == "1";
      },
      set(value) {
        this.item.table_of_contents = value;
      },
    },
    myPages() {
      return this.getPages
        .filter((p) => p.writing_id == this.item.ID)
        .filter((p) => p.post_status == "publish")
        .sort((a, b) => a.page_position - b.page_position);
    },
  },
  methods: {
    activateItem() {
      this.isActivated = !this.isActivated;
      this.item.table_of_contents = this.isActivated ? "1" : "0";
      this.$store.dispatch("updatePageMeta", [
        this.item.ID,
        "table_of_contents",
        this.isActivated ? 1 : 0,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
          });
          // this.$emit("reload-preview");
        },
      ]);
    },

    triggerEdit() {
      this.$modal.show(
        PageEditorModal,
        {
          item: this.item,
          closeTo: "toc",
        },
        {
          name: "modal-" + this.item.ID,
        }
      );
    },
  },
  mounted() {
    this.$store.dispatch("loadPages", [
      this.item.ID,
      () => {
        this.isLoading = false;
      },
    ]);
  },
};
</script>
<style scoped>
.fa {
  cursor: pointer;
}
.fa.activated {
  color: green;
}
</style>