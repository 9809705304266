<template>
  <div class="document-editor">
    <div class="document-editor-toolbar"></div>
    <div class="document-editor-editable" :style="defaultFontStyle">
      <ckeditor
        :editor="editor"
        v-model="postContent"
        :config="editorConfig"
        @ready="onReady"
        @input="onEditorInput"
        :style="editableStyles"
      >
      </ckeditor>
    </div>
  </div>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import Editor from "../third_party/ckeditor5/src/ckeditor.js";
import PicturesListModal from "./PicturesListModal";
import DefaultFontModal from "./DefaultFontModal";

import { mapGetters } from "vuex";
import {
  toolbars,
  fonts,
  fontSizes,
  fontColors,
  tableConfig,
} from "../data/editor-config.js";
import { pasteContentIntercept } from "../utils/pasteContentIntercept";

import "../assets/css/browser.css";

export default {
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: Editor.Editor,
      editorContent: ``,
      editor2: {},
      lineHeight: `1.5rem`,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    scale: {
      type: Number,
      default: 1,
    },
    title: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(["getGlobalSettings", "getFonts", "getDefaults"]),
    currentFont() {
      return this.getDefaults.default_font_family;
    },
    currentSize() {
      return this.getDefaults.default_font_size;
    },
    filteredToolbars() {
      return toolbars
        .filter((i) => i !== "horizontalLine")
        .filter((i) => i !== "webSpeech");
    },
    editorConfig() {
      return {
        toolbar: {
          items: [...this.filteredToolbars],
        },
        language: "en",
        fontFamily: {
          options: [...fonts],
          supportAllValues: true,
        },
        fontSize: {
          options: [...fontSizes],
          supportAllValues: true,
        },
        fontColor: {
          colors: [...fontColors],
        },
        fontBackgroundColor: {
          colors: [...fontColors],
        },
        image: {
          toolbar: ["imageStyle:full", "imageStyle:side", "linkImage"],
        },
        table: {
          ...tableConfig,
        },
        wproofreader: {
          lang: "en_US", // sets the default language
          serviceId: "LnWMGEwhyYUHUm2", // required for the Cloud version only
          srcUrl:
            "https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js",
        },
      };
    },

    postTitle() {
      const titleContentElement = this.editor2.model.document
        .getRoot()
        .getChild(0);
      const title = this.editor2.data.stringify(titleContentElement, {});
      return title.replace(/(<([^>]+)>)/gi, "");
    },

    tocIndicator() {
      return "<hr>";
    },

    postContent: {
      get() {
        let content = this.item?.ID
          ? `${this.item.post_content}`
          : `<h3>${this.prompt.post_title}</h3>`;
        if (content.indexOf(this.tocIndicator) < 0) {
          content = content + this.tocIndicator;
        }
        return content;
      },
      set(value) {
        if (this.postTitle.length > 0) {
          this.item.post_title = this.postTitle;
        }
        this.item.post_content = value;
      },
    },

    topPadding() {
      return this.title ? 1 : 2.6;
    },

    editableStyles() {
      return {
        transform: `scale(${this.scale})`,
        width: `${21.59 * this.scale}cm`,
        padding: `${this.topPadding * this.scale}cm ${2.6 * this.scale}cm ${
          2.6 * this.scale
        }cm ${2.6 * this.scale}cm`,
        minHeight: `${27.94 * this.scale}cm`,
        fontFamily: "inherit",
        fontSize: "inherit",
        lineHeight: "inherit",
      };
    },

    defaultFontStyle() {
      return {
        fontFamily: this.currentFont,
        fontSize: `${this.currentSize}px`,
        lineHeight: this.item.lineHeight ?? `1.5rem`,
      };
    },
  },
  methods: {
    onReady(editor) {
      document
        .querySelector(".document-editor-toolbar")
        .appendChild(editor.ui.view.toolbar.element);

      this.editor2 = editor;

      // filestack
      const filestackUpload = editor.commands.get("filestackUpload");
      filestackUpload.on("execute", (props) => {
        this.showPicturesList(props);
      });

      // default font
      const defaultFontConfig = editor.commands.get("defaultFontConfig");
      defaultFontConfig.on("execute", (props) => {
        this.showDefaultFont(props);
      });

      // paste plain text
      pasteContentIntercept(editor, () => {
        console.log("pasted plain text");
      });

      // line spacing
      const lineSpacing = editor.commands.get("lineSpacing");
      lineSpacing.on("execute", (props) => {
        this.lineHeight = props.return;
        this.$store.dispatch("updatePageMeta", [
          this.item.ID,
          "line_spacing",
          props.return,
          () => {
            this.$toast.open({
              message: "Writing Updated!",
              position: "bottom-left",
              type: "success",
            });
          },
        ]);
      });
      lineSpacing.set("value", this.lineHeight);
    },
    onEditorInput() {
      if (this.postContent.indexOf(this.tocIndicator) < 0) {
        this.postContent = this.postContent + this.tocIndicator;
      }
      this.$emit("triggerUpdate");
    },

    showPicturesList(props) {
      this.$modal.show(
        PicturesListModal,
        {
          props: props,
        },
        {
          name: `pictures-list`,
          width: "55%",
        }
      );
    },

    showDefaultFont(props) {
      this.$modal.show(
        DefaultFontModal,
        {
          props: props,
        },
        {
          name: "default-font",
          width: "55%",
          height: "220px",
        }
      );
    },
  },
  mounted() {
    this.lineHeight = this.item.line_spacing ?? `1.5rem`;
  },
};
</script>
<style>
.document-editor-toolbar > .ck-toolbar {
  z-index: 1;
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);
  border-bottom: 1px solid var(--ck-color-toolbar-border);
  border: 0;
  border-radius: 0;
}
.document-editor-toolbar .ck.ck-reset.ck-list {
  overflow-y: scroll;
  max-height: 240px;
}
.document-editor-toolbar .ck.ck-reset.ck-list .ck.ck-button__label {
  font-size: 12px !important;
}
/*
.document-editor-editable .ck-content .page-break {
  margin-left: -2.6cm;
  background-color: #ddd;
  width: 21.55cm;
  margin-top: 2.6cm;
  margin-bottom: 2.6cm;
}
*/
.document-editor .ck-editor__editable h1,
.document-editor .ck-editor__editable h2,
.document-editor .ck-editor__editable h3,
.document-editor .ck-editor__editable h4,
.document-editor .ck-editor__editable h5,
.document-editor .ck-editor__editable h6 {
  font-family: inherit;
}
.ck-content figcaption {
  display: block;
}
.ck-content .image > figcaption {
  display: block;
}
.ck-content figcaption.ck-placeholder {
  display: block;
}
</style>
<style scoped>
.document-editor-toolbar,
.document-editor-toolbar > .ck-toolbar {
  z-index: 1;
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);
  border-bottom: 1px solid var(--ck-color-toolbar-border);
  border: 0;
  border-radius: 0;
}
.document-editor-editable {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: calc(100% - 80px);

  /* This element is a flex container for easier rendering. */
  flex-flow: column nowrap;

  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
  overflow-x: scroll;
  background-color: #ddd;
  line-height: 1.5;
}

@media (min-width: 1000px) {
  .document-editor-editable {
    overflow-x: hidden;
  }
}

.document-editor .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 21.59cm;
  min-height: 27.94cm;
  background-color: #fff;

  /* Keep the "page" off the boundaries of the container. */
  padding: 2.6cm 2.6cm 2.6cm 2.6cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 20px auto 50px;
  font-family: "Lato", serif;
  font-size: 14px;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Arsenal:ital,wght@0,400;0,700;1,400;1,700&family=Cinzel:wght@400;500;600;700;800;900&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Architects+Daughter&family=Shadows+Into+Light&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
</style>