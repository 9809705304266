<template>
  <div>
    <iframe
      ref="iframe"
      :src="preview_url"
      border="0"
      width="100%"
      height="570px"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    toc: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getGlobalSettings"]),
    preview_url() {
      if (this.item.ID) {
        return `/wp-admin/admin-ajax.php?action=call_fjtl_preview_pdf&post_id=${
          this.item.ID
        }&background=${this.getGlobalSettings.designs}&toc=${
          this.toc ? 1 : 0
        }#toolbar=0&navpanes=0&scrollbar=0&view=Fit`;
      } else {
        return "";
      }
    },
  },
  methods: {
    reload() {
      this.$refs.iframe.contentWindow.location.reload();
    },
  },
  mounted() {},
};
</script>
<style>
.tox-tinymce-inline {
  z-index: 999;
}
</style>
<style scoped>
.card-body {
  max-height: 500px;
  overflow-y: scroll;
}
iframe {
  border: none;
}
</style>