<template>
  <label class="form-check" v-if="item.page_type !== `writing`">
    <input
      class="form-check-input"
      type="checkbox"
      id="inlineCheckbox1"
      v-model="isPublished"
      @change="triggerChange"
    />
  </label>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    isPublished: {
      get() {
        return this.item.legacy_journal;
      },
      set(value) {
        this.item.legacy_journal = value ? true : false;
      },
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    triggerChange() {
      this.$store.dispatch("updatePageMeta", [
        this.item.ID,
        "legacy_journal",
        this.isPublished ? "1" : "0",
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
            type: "success",
          });
        },
      ]);
    },
  },
  mounted() {
    // console.log(this.item);
  },
};
</script>
<style scoped>
.form-check {
  position: absolute;
  right: 0;
  z-index: 1;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 0;
}
.form-check input {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
}
</style>

<style>
#legacy-journal-app-container .grid-item.active button.btn-edit-cover,
.trigger__edit-button {
  z-index: 2;
  position: relative;
}
</style>