<template>
  <div class="container height-100">
    <CloseModalButton @close="closeModal" @resize="resizeModal" />
    <PageNavButtons
      :item="getItem"
      :item_id="getItemId"
      ref="viewNav"
      @nav-change="triggerChangeNav"
      :showPictures="false"
      :showItems="false"
      default="preview"
      :showPreview="true"
      :showContent="false"
    />
    <div class="page-editor-modal row height-100">
      <div
        class="pt-3 pb-3 height-100"
        :class="contentWidthClass"
        v-if="active_view == `items`"
      >
        <div class="card height-100">
          <button
            class="btn btn-success btn-sm btn-save"
            v-if="show_save"
            @click="saveChanges"
          >
            <i class="fa fa-save"></i> Save
          </button>
          <div class="card-body">
            <div class="list-group" v-if="hasGroups">
              <TableOfContentsGroup
                v-for="(group, index) in getWritingGroupPages"
                :key="index"
                :group="group"
              />
              <TableOfContentsNoGroup />
            </div>
            <div class="list-group" v-else>
              <table-of-contents-parent-item
                v-for="parent in sortedParentItems"
                :key="parent.ID"
                :item="parent"
                @reload-preview="reloadPreview"
                class="list-group-item"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-12 pt-3 pb-3 height-100"
        v-if="active_view == `design`"
      >
        <DesignEditorModal :item="item" />
      </div>
      <div
        class="col-md-12 pt-3 pb-3 height-100"
        v-if="active_view == `content`"
      >
        <ContentEditorModalTOC :item="item" />
      </div>
      <div
        class="col-md-12 pt-3 pb-3 height-100"
        v-if="active_view == `preview`"
      >
        <PreviewHtml :item="item" :showDownload="false" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CloseModalButton from "./CloseModalButton";
import PageNavButtons from "./PageNavButtons";
import TableOfContentsParentItem from "./TableOfContentsParentItem.vue";
import TableOfContentsGroup from "./TableOfContentsGroup.vue";
import TableOfContentsNoGroup from "./TableOfContentsNoGroup.vue";
import ContentEditorModalTOC from "./ContentEditorModalTOC";
import DesignEditorModal from "./DesignEditorModal";
import PreviewHtml from "./PreviewHtml.vue";

export default {
  components: {
    CloseModalButton,
    PageNavButtons,
    TableOfContentsParentItem,
    TableOfContentsGroup,
    TableOfContentsNoGroup,
    DesignEditorModal,
    ContentEditorModalTOC,
    PreviewHtml,
  },
  data() {
    return {
      active_view: "preview",
      selected_design: "",
      timer: 0,
      show_save: false,
      show_preview: false,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters([
      "getDefaults",
      "getLegacyJournal",
      "getPages",
      "getWritingGroupPages",
    ]),
    hasGroups() {
      return this.getWritingGroupPages.length > 0;
    },
    sortedParentItems() {
      const ActiveItems = this.getLegacyJournal
        .filter((a) => a.legacy_journal == 1)
        .slice()
        .sort((a, b) => {
          return a.position - b.position;
        });
      return ActiveItems;
    },
    getItem() {
      return this.item;
    },
    getItemId() {
      return this.getDefaults.cover_page_id
        ? parseInt(this.getDefaults.cover_page_id)
        : "";
    },
    title: {
      get() {
        return this.item.post_title;
      },
      set(value) {
        this.item.post_title = value;
      },
    },
    contentWidthClass() {
      return this.show_preview ? `col-md-7` : `col-md-12`;
    },
    hasPageNav() {
      return this.$root?.sidebar_tabs === `1`;
    },
  },
  methods: {
    setPageDesign() {
      const design = this.$refs.page_design.design;
      if (this.selected_design === design.id) return;
      this.selected_design = design.id;
      this.item.page_design = design.id;
      this.item.design = design;
      this.$store.dispatch("updatePageMeta", [
        this.item.ID,
        "page_design",
        `${design.id}`,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
          });
          this.reloadPreview();
        },
      ]);
    },
    reloadPreview() {
      this.$refs.preview.reload();
    },
    closeModal() {
      this.$modal.hide("modal-page");
    },
    resizeModal() {
      this.$emit("resize");
    },
    triggerUpdate() {
      clearTimeout(this.timer);
      this.show_save = true;
      this.timer = setTimeout(() => {
        this.saveChanges();
      }, 5000);
    },
    saveChanges() {
      clearTimeout(this.timer);
      this.$store.dispatch("updatePage", [
        this.item.ID,
        this.item.post_title,
        this.item.post_content,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
            type: "success",
          });
          this.reloadPreview();
        },
      ]);
      this.show_save = false;
    },
    triggerChangeNav() {
      this.active_view = this.$refs.viewNav.isActive;
    },
    activateItem() {},
  },
  mounted() {
    if (this.getLegacyJournal.length === 0) {
      this.$store.dispatch("loadLegacyJournal", [
        "1",
        () => {
          this.loading = false;
        },
      ]);
      this.$store.dispatch("loadWritingsGroupPages", () => {});
    }
  },
};
</script>
<style scoped>
.scrolled {
  height: 565px;
  overflow-y: scroll;
}
.card.page-design {
  min-height: 220px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
}
.card.page-design:hover {
  border: 1px solid green;
}
.card.page-design.active {
  border: 1px solid green;
}
.btn-save {
  position: absolute;
  z-index: 1;
  right: -10px;
  top: -10px;
}
.card-body {
  overflow-y: scroll;
  height: 565px;
}
</style>