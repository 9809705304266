<template>
  <div class="container height-inherit" ref="modal">
    <CloseModalButton @close="closeModal" />
    <PageNavButtons
      v-if="hasPageNav"
      :item="getItem"
      :item_id="getItemId"
      ref="viewNav"
      @nav-change="triggerChangeNav"
      :showPictures="false"
      :showPreview="true"
      :showSettings="true"
      :showContent="false"
      :default="active_view"
    />
    <div class="page-editor-modal row height-inherit">
      <div
        class="col-md-12 pt-3 pb-3 height-inherit"
        v-if="active_view == `design`"
      >
        <PageDesignsComponent
          groups="cover"
          @set-design="setPageDesign"
          ref="page_design"
          :default="item.page_design"
        />
      </div>
      <div
        class="col-md-12 pt-3 pb-3 height-inherit"
        v-if="active_view == `content`"
      >
        <div class="card cover-page-modal height-inherit">
          <button
            class="btn btn-success btn-sm btn-save"
            v-if="show_save"
            @click="saveChanges"
          >
            <i class="fa fa-save"></i> Save
          </button>

          <CKCustomEditor
            :item="item"
            @triggerUpdate="triggerUpdate"
            ref="editor"
          />
        </div>
      </div>
      <div class="col-md-12 pt-3 pb-3" v-if="active_view == `preview`">
        <PreviewHtml :item="item" />
      </div>
      <div class="col-md-12 pt-3 pb-3" v-if="active_view == `settings`">
        <CoverPageSettings :item="item" />
      </div>
      <div class="col-md-12 pt-3 pb-3" v-if="active_view == `my-uploads`">
        My Uploads
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CloseModalButton from "./CloseModalButton";
import PageNavButtons from "./PageNavButtons";
import PageDesignsComponent from "./PageDesignsComponent.vue";
import CKCustomEditor from "./CKCustomEditor";
import PreviewHtml from "./PreviewHtml.vue";
import CoverPageSettings from "./CoverPageSettings.vue";

export default {
  components: {
    CloseModalButton,
    PageNavButtons,
    PageDesignsComponent,
    CKCustomEditor,
    PreviewHtml,
    CoverPageSettings,
  },
  data() {
    return {
      active_view: "settings",
      selected_design: "",
      timer: {
        type: Object,
        default: 0,
      },
      show_save: false,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["getDefaults", "getPageDesigns"]),
    getSpecificDesigns() {
      return this.getPageDesigns.filter((d) =>
        d.groups.find((g) => g === "cover")
      );
    },
    getItem() {
      return this.item;
    },
    getItemId() {
      return parseInt(this.getDefaults.cover_page_id);
    },
    title: {
      get() {
        return this.item.post_title;
      },
      set(value) {
        this.item.post_title = value;
      },
    },
    content: {
      get() {
        return this.item.post_content;
      },
      set(value) {
        this.item.post_content = value;
      },
    },
    hasPageNav() {
      return this.$root?.sidebar_tabs === `1`;
    },
  },
  methods: {
    setPageDesign() {
      const design = this.$refs.page_design.design;
      if (this.selected_design === design.id) return;
      this.selected_design = design.id;
      this.item.page_design = design.id;
      this.item.design = design;
      this.$store.dispatch("updatePageMeta", [
        this.item.ID,
        "page_design",
        `${design.id}`,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
          });
          // this.reloadPreview();
          this.active_view = "settings";
          this.$refs.viewNav.active = "settings";
        },
      ]);
    },
    reloadPreview() {
      this.$refs.preview?.reload();
    },
    closeModal() {
      this.$modal.hide("modal-page");
    },
    triggerUpdate() {
      clearTimeout(this.timer);
      this.show_save = true;
      this.timer = setTimeout(() => {
        this.saveChanges();
      }, 5000);
    },
    saveChanges() {
      clearTimeout(this.timer);
      this.$store.dispatch("updatePage", [
        this.item.ID,
        this.item.post_title,
        this.item.post_content,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
            type: "success",
          });
          this.reloadPreview();
        },
      ]);
      this.show_save = false;
    },
    triggerChangeNav() {
      this.active_view = this.$refs.viewNav.isActive;
    },
  },
  mounted() {
    if (this.$root.designs === "0") {
      this.active_view = "settings";
    } else {
      this.active_view = "design";
    }
    if (this.$root?.sidebar_default_view) {
      this.active_view = this.$root?.sidebar_default_view;
    }
  },
};
</script>
<style>
</style>
<style scoped>
.card {
  max-height: 100%;
  border: none;
}
.card-body {
  height: 100%;
  overflow-y: scroll;
}
.scrolled {
  height: 100%;
  overflow-y: scroll;
}
.card.page-design {
  min-height: 220px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
}
.card.page-design:hover {
  border: 5px solid green;
}
.card.page-design.active {
  border: 5px solid green;
}
.btn-save {
  position: absolute;
  z-index: 1;
  right: -10px;
  top: -10px;
}
</style>