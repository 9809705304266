var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('CloseModalButton',{on:{"close":_vm.closeModal}}),(_vm.hasPageNav)?_c('PageNavButtons',{ref:"viewNav",attrs:{"item":_vm.getItem,"item_id":_vm.getItemId,"showPictures":false},on:{"nav-change":_vm.triggerChangeNav}}):_vm._e(),_c('div',{staticClass:"page-editor-modal row"},[(_vm.active_view == `content`)?_c('div',{staticClass:"col-md-7 pt-3 pb-3"},[_c('div',{staticClass:"card"},[(_vm.show_save)?_c('button',{staticClass:"btn btn-success btn-sm btn-save",on:{"click":_vm.saveChanges}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" Save ")]):_vm._e(),_c('Editor',{staticClass:"card-header",attrs:{"output-format":"text","inline":true,"initial-value":_vm.item.post_title,"init":{
            height: 300,
            max_height: 10,
            menubar: false,
            plugins: [],
            toolbar: '',
            statusbar: false,
          }},on:{"onKeyUp":_vm.triggerUpdate,"onChange":_vm.triggerUpdate},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)]):_vm._e(),(_vm.active_view == `design`)?_c('div',{staticClass:"col-md-7 pt-3 pb-3"},[_c('page-designs-component',{ref:"page_design",attrs:{"groups":"default","default":_vm.item.page_design},on:{"set-design":_vm.setPageDesign}})],1):_vm._e(),_c('div',{staticClass:"col-md-5 d-md-block d-sm-none pt-3 pb-3"},[_c('PdfPreviewModal',{ref:"preview",attrs:{"item":_vm.item}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }